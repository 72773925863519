import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Banner from 'common/source/containers/Banner';
import Container from 'common/source/components/UI/Container';
import Box from 'reusecore/src/elements/Box';
import { Icon } from 'react-icons-kit';
import { phoneIncoming } from 'react-icons-kit/feather/phoneIncoming';
import { mail } from 'react-icons-kit/feather/mail';
import ContactViewWrapper from './contactView.style';

import ContactForm from './ContactForm';

class ContactView extends React.Component {
  render() {
    const { row, col } = this.props;
    return (
      <ContactViewWrapper>
        <Banner
          heading='Contacto'
          subtitle='Cualquier consulta sobre nuestros productos y servicios, no dudes en contactarnos
            mediante cualquier método. Puedes comunicarte por mail en cualquier momento. Durante periodo de cuarentena solo estaremos atendiendo correos, llamadas al teléfono celular y WhatsApp.'
        >
          <Box className='row' {...row}>
            <Box className='col' {...col}>
              <h3>TELÉFONO CONTACTO</h3>
              <div className='contact_detail'>
                <span>
                  <Icon icon={phoneIncoming} size={32} />
                </span>
                <p>+56 (9) 8429 8582</p>
              </div>
              <div className='contact_detail'>
                <span>
                  <Icon icon={phoneIncoming} size={32} />
                </span>
                <p>+56 (2) 3267 0070</p>
              </div>
            </Box>
            <Box className='col' {...col}>
              <h3>EMAIL CONTACTO</h3>
              <div className='contact_detail'>
                <span>
                  <Icon icon={mail} size={32} />
                </span>
                <p>contacto@light-up.cl</p>
              </div>
            </Box>
          </Box>
        </Banner>
        <Container>
          <Box pt='50px' pb='50px'>
            <h2 style={{ paddingLeft: 30 }}>Escríbenos!</h2>
            <ContactForm />
          </Box>
        </Container>
      </ContactViewWrapper>
    );
  }
}

ContactView.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object
};

ContactView.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    pt: '15px',
    pb: '15px',
    alignItems: 'center'
  },
  col: {
    // pr: '15px',
    // pl: '15px',
    width: ['100%', '50%', '50%', '50%']
  }
};

export default ContactView;
