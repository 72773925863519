import styled from 'styled-components';

const ContactViewWrapper = styled.div`
  .col {
    color: ${({ theme }) => theme.colors.white};
    h3,
    h4 {
      font-size: 14px;
    }
    .contact_detail {
      display: flex;
      align-items: center;
      span {
        color: ${({ theme }) => theme.colors.primary};
        margin-right: 10px;
      }
      p {
        font-weight: 500;
      }
    }
  }
`;

export default ContactViewWrapper;
