import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/source/contexts/DrawerContext';

import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/Navbar';
// import BlogSection from 'common/source/containers/BlogSection';
// import TimelineSection from 'common/source/containers/TimelineSection';
// import ContactSections from 'common/source/containers/Contact';
// import PartnerSection from 'common/source/containers/Partner';
// import FeatureSection from 'common/source/containers/FeatureSection';
import Footer from 'common/source/containers/Footer';
import ContactView from 'common/source/containers/ContactView';

import SEO from '../components/SEO';
import config from '../../data/SiteConfig';

class ContactPage extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO pageTitle={`Contacto | ${config.siteTitle}`} path="/contacto" />

          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <ContactView />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}
export default ContactPage;
