import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Recaptcha from 'react-recaptcha';
import Box from 'reusecore/src/elements/Box';
import Input from 'reusecore/src/elements/Input';
import Button from 'reusecore/src/elements/Button';
import ErrorMessageComponent from './errorMessage';
import SuccessMessageComponent from './successMessage';
import { validateFunc, postData, DisplayFormikState } from './helperFunctions';

const initialValues = {
  name: '',
  lastname: '',
  email: '',
  phone: '',
  message: ''
};

class ContactForm extends React.Component {
  // eslint-disable-next-line
  recaptchaInstance = null;
  // eslint-disable-next-line
  state = {
    isVerified: false,
    successMessage: ''
  };

  onSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    // setTimeout(() => {
    //   alert(JSON.stringify(values, null, 2));
    // }, 400);
    const response = await postData(values);
    // console.log({ response });
    if (response.status !== 200) {
      this.recaptchaInstance.reset();
      // resetForm();
      await this.setState({
        successMessage: 'Error en el envío. No se pudo enviar el correo... :(',
        isVerified: false
      });
    }
    if (response.status === 200) {
      this.recaptchaInstance.reset();
      resetForm();
      await this.setState({
        successMessage: 'Mensaje registrado exitosamente!',
        isVerified: false
      });
    }
    setSubmitting(false);
  };

  recaptchaLoaded = () => {
    // console.log('reCaptcha Loaded...');
  };

  onVerifyCallback = response => {
    // console.log('veifying captcha');
    if (response) {
      this.setState({ isVerified: true });
    }
  };
  onExpireCallback = () => {
    this.setState({ isVerified: false });
  };

  render() {
    const { row, col } = this.props;
    const { isVerified, successMessage } = this.state;
    return (
      <Fragment>
        <Formik
          initialValues={initialValues}
          validate={validateFunc}
          onSubmit={this.onSubmit}
        >
          {props => {
            const {
              values,
              errors,
              touched,
              dirty,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              handleReset,
              setFieldValue,
              setFieldTouched
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                <Box className='row' {...row}>
                  <Box className='col' {...col}>
                    <Box className='row' {...row}>
                      <Box className='col' {...col}>
                        <div>
                          <Input
                            type='text'
                            name='name'
                            label='Nombre'
                            aria-label='Nombre'
                            placeholder='John'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            withError={errors.name && touched.name}
                          />
                          {errors.name && touched.name && (
                            <ErrorMessageComponent error={errors.name} />
                          )}
                        </div>
                      </Box>
                      <Box className='col' {...col}>
                        <div>
                          <Input
                            type='text'
                            name='lastname'
                            label='Apellido'
                            aria-label='Apellido'
                            placeholder='Su apellido'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastname}
                            withError={errors.lastname && touched.lastname}
                          />
                          {errors.lastname && touched.lastname && (
                            <ErrorMessageComponent error={errors.lastname} />
                          )}
                        </div>
                      </Box>
                    </Box>
                    <Box className='row' {...row}>
                      <Box className='col' {...col}>
                        <div>
                          <Input
                            type='email'
                            name='email'
                            label='Email'
                            aria-label='Email'
                            placeholder='jon@email.com'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            withError={errors.email && touched.email}
                          />
                          {errors.email && touched.email && (
                            <ErrorMessageComponent error={errors.email} />
                          )}
                        </div>
                      </Box>
                      <Box className='col' {...col}>
                        <div>
                          <Input
                            type='text'
                            name='phone'
                            label='Teléfono'
                            aria-label='Teléfono'
                            placeholder='+56xxxxxxxx'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                            withError={errors.phone && touched.phone}
                          />
                          {errors.phone && touched.phone && (
                            <ErrorMessageComponent error={errors.phone} />
                          )}
                        </div>
                      </Box>
                    </Box>
                    <Box width='100%' p='20px 15px'>
                      <Recaptcha
                        ref={e => (this.recaptchaInstance = e)}
                        sitekey='6LcQ9qkUAAAAADYUycvbB_a4BpN91UpRYYQMK9Vq'
                        render='explicit'
                        onloadCallback={this.recaptchaLoaded}
                        verifyCallback={this.onVerifyCallback}
                        expiredCallback={this.onExpireCallback}
                      />
                    </Box>
                  </Box>
                  <Box className='col' {...col}>
                    <Box
                      className='row'
                      {...row}
                      pr={['5px', '15px']}
                      pl={['5px', '15px']}
                    >
                      <Box width='100%'>
                        <Input
                          type='textarea'
                          name='message'
                          label='Mensaje'
                          aria-label='Mensaje'
                          placeholder='Escribe tu mensaje aquí...'
                          rows='12'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.message}
                          withError={errors.message && touched.message}
                        />
                        {errors.message && touched.message && (
                          <ErrorMessageComponent error={errors.message} />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box width='100%' flexBox justifyContent='center'>
                  {successMessage.length > 0 && (
                    <SuccessMessageComponent message={successMessage} />
                  )}
                </Box>
                <Box width='100%' p='15px 30px' style={{ textAlign: 'end' }}>
                  <Fragment>
                    <Button
                      type='submit'
                      disabled={isSubmitting || !isVerified}
                      title={isSubmitting ? 'Enviando...' : 'Enviar mensaje'}
                      colors={
                        isSubmitting || !isVerified
                          ? 'disabledWithBg'
                          : 'primaryWithBg'
                      }
                      // variant="outlined"
                    />
                  </Fragment>

                  {/* <Fragment>
                    <Button
                      type="button"
                      className="outline"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}
                      title="Reset"
                      colors="primaryWithBg"
                      varient="textButton"
                    />
                  </Fragment> */}
                </Box>
                {/* <DisplayFormikState {...props} /> */}
              </form>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

// ContactForm style props
ContactForm.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object
};

// ContactForm default style
ContactForm.defaultProps = {
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    pb: '1rem'
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 2],
    pr: ['5px', '15px'],
    pl: ['5px', '15px']
  }
};

export default ContactForm;
